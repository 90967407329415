<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'entity'" class="content-item">
                <h4>Customers</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.entities"
                    [entityTypeList]="['individual', 'individuals_in_group', 'company_group']"
                    (valueChanges)="onValueChanges('entities', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'compensation_profile_type'" class="content-item">
                <h4>Deal Element</h4>
                <app-report-dropdown
                    [list]="listOfCompensationProfileType"
                    [value]="reportSettings.compensation_profile_type"
                    (valueChanges)="onValueChanges('compensation_profile_type', $event)"
                    titleAttribute="title"
                    valueAttribute="value"
                >
                </app-report-dropdown>
            </div>

            <div *ngIf="activePanel === 'is_include_cap_condition'">
                <app-report-edit-bar-button-list
                    [list]="listOfBooleanCondition"
                    [value]="reportSettings.is_include_cap_condition"
                    (valueChanges)="onValueChanges('is_include_cap_condition', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
